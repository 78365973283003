//$(function($) {


if ($('.js-password-change').length) {
    $('.profileform__password-wrap').hide();

    $('.profileform__password-change a').on('click', function () {
        $('.profileform__password').toggleClass('profileform__password--show');
        $("input[name=changepass]").val($('.profileform__password').hasClass("profileform__password--show"));
        $('.profileform__password-wrap').toggle('300');
        return false;
    });
}


if ($('.js-input').length) {
    var $inputs = $('input', '.js-input');
    var $textareas = $('textarea', '.js-input');

    $inputs.each(function () {
        if ($(this).val().trim() !== '')
            $(this).closest('.form__elem').addClass('form__elem--filled');

        $(this).on('focus', function () {
            $(this).closest('.form__elem').addClass('form__elem--filled');
        });

        $(this).on('blur', function () {
            if ($(this).val().trim() === '')
                $(this).closest('.form__elem').removeClass('form__elem--filled');
        });
    });

    $textareas.each(function () {
        if ($(this).val().trim() !== '')
            $(this).closest('.form__elem').addClass('form__elem--filled');

        $(this).on('focus', function () {
            $(this).closest('.form__elem').addClass('form__elem--filled');
        });

        $(this).on('blur', function () {
            if ($(this).val().trim() === '')
                $(this).closest('.form__elem').removeClass('form__elem--filled');
        });
    });
}

MetaFormsHandler.bind({
    target: "form.js-ajax-form"
});

//Модалка в замен фансибокс
if ($('.js-modal').length) {
    $('.js-modal').on('click', function () {
        var target = $(this).attr('href');
        $(target).addClass('modal--open');
        $('html').addClass('modal-lock');
        $('.container').addClass('container__modal--open');

        if (target == "#search") {
            $(".js-search").focus();
        }

        return false;
    });

    $('.modal__close, .js-modal-close').on('click', function (e) {
        $(this).closest('.modal').removeClass('modal--open');
        $('html').removeClass('modal-lock');
        $('.container').removeClass('container__modal--open');
        e.stopPropagation();
        e.preventDefault();
    });

    $('.modal__overlay').on('click', function (e) {
        $(this).closest('.modal').removeClass('modal--open');
        $('html').removeClass('modal-lock');
        $('.container').removeClass('container__modal--open');
        e.stopPropagation();
    });

    //Исключение для модалки оплаты
    //$('.modal--addtocart .modal__overlay').off('click');

}

// Оживляем глаз для пароля в модальной форме
$(".eye").on("click", function () {
    if ($("#password").attr("type") == "password") {
        $(this).addClass("show");
        $("#password").attr("type", "text");
        $("#new_password").attr("type", "text");
        $("#new_password_confirm").attr("type", "text");
        $("#password_confirm").attr("type", "text");
    } else {
        $(this).removeClass("show");
        $("#password").attr("type", "password");
        $("#new_password").attr("type", "password");
        $("#new_password_confirm").attr("type", "password");
        $("#password_confirm").attr("type", "password");
    }
    return false;
});

document.addEventListener("DOMContentLoaded", function (event) {

    window.site = {};
    window.site.obj = {

        map: function () {
            var newMap = document.querySelector('.js-map');
            //     coords = newMap.dataset.coords.split(',');
            // ymaps.ready(function () {
            //     let myMap = new ymaps.Map("yaMap", {
            //         center: [coords[0], coords[1]],
            //         zoom: newMap.dataset.zoom || 15,
            //         controls: ['largeMapDefaultSet']f
            //     });
            //     myMap.controls.add('zoomControl', {
            //         size: 'small'
            //     });
            //     myMap.behaviors.disable('scrollZoom');
            //     let myPlacemark = new ymaps.Placemark(coords, {}, {
            //         preset: 'islands#redShoppingCircleIcon'
            //     });
            //     myMap.geoObjects.add(myPlacemark);
            // });
            // return;

        },

        init: function () {

            let _self = this;

            let body = document.querySelector('body'),
                html = document.querySelector('html'),
                wrapper = document.querySelector('.wrapper'),
                brandsTitle = document.querySelector('.brands__title') || false,
                promo = document.querySelector('.promo') || false,
                headerLocation = document.querySelector('.header .js-location') || false,
                headerBurger = document.querySelector('.header__burger') || false,
                contactLink = document.querySelector('.js-contact-link') || false,
                location = document.querySelector('.js-location') || false,
                closemap = document.querySelector('.js-closemap') || false,
                openmap = document.querySelector('.js-openmap') || false,
                closePopup = document.querySelectorAll('.js-close-popup'),
                showPopup = document.querySelectorAll('.js-show-popup[data-action]'),
                popups = document.querySelectorAll('.popup'),
                header = document.querySelector('.header') || false,
                grayBtn = document.querySelectorAll('.js-open-popup') || false,
                mainSlider = document.querySelector('.slider-home-page__content') || false,
                sliderTime = mainSlider ? parseInt(mainSlider.getAttribute('data-delay')) * 1000 : 7000,
                prevPopup;

            $(".slider-home-page__content").slick({
                arrows: true,
                fade: true,
                dots: true,
                draggable: false,
                slidesToShow: 1,
                infinite: true,
                autoplay: true,
                autoplaySpeed: sliderTime,
                prevArrow: ".slider-home-page__button--left",
                nextArrow: ".slider-home-page__button--right"
            })

            class Opacity {
                constructor(direction, target, start, end) {
                    this.start = start
                    this.end = end
                    this.direction = direction
                    this.track = Math.abs(this.end - this.start)
                    this.target = target
                    this.SetOpacity()
                    window.addEventListener('scroll', () => {
                        if (window.scrollY >= this.start || window.scrollY <= this.end) {
                            this.SetOpacity()
                        }
                    })
                }

                SetOpacity() {
                    let opa = (((window.scrollY - this.start) / (this.track / 100)) / 100).toFixed(2)
                    if (this.direction === true) opa = 1 - opa
                    if (opa > 0 && opa <= 1) {
                        this.target.style.opacity = opa
                        this.target.style.visibility = ''
                    } else if (opa < 0) {
                        this.target.style.opacity = '0'
                        this.target.style.visibility = 'hidden'
                    } else if (opa >= 1) {
                        this.target.style.opacity = '1'
                        this.target.style.visibility = ''
                    }
                }
            }

            function openPopupProduct(itemBtn) {
                const loader = document.querySelector('.popup-ajax');
                loader.classList.add('popup--active')
                document.body.classList.add('popup-open')
                document.querySelector('.menu').classList.add('header--active');
                html.classList.add('scroll')
                $.ajax({
                    url: window.location.origin + '/catalog/get_popup/' + itemBtn.getAttribute('data-id-product') + '/',
                    cache: false,
                    success: function (data) {
                        loader.classList.remove('popup--active')
                        document.body.insertAdjacentHTML("beforeend", data)

                        $('.js-product').on("click", ".js-cart_buy", function (e) {
                            cartBuy(e, $(this))
                        });

                        $('.citem__description--tab').click(function () {
                            var id = $(this).attr('data-behavior'),
                                content = $('.citem__description--text[data-tab="' + id + '"]');

                            $('.citem__description--tab.active').removeClass('active');
                            $(this).addClass('active');

                            $('.citem__description--text.active').removeClass('active');
                            content.addClass('active');
                        });

                        $(".popup--active .citem__left--mainslide.pop").slick({
                            arrows: false,
                            fade: true,
                            draggable: false,
                            asNavFor: ".popup--active .citem__left--slides.pop",
                        });

                        $(".popup--active .citem__left--slides.pop").slick({
                            dots: false,
                            arrows: true,
                            infinite: true,
                            slidesToShow: 3,
                            prevArrow: ".popup--active .citem__left--slides-prev",
                            nextArrow: ".popup--active .citem__left--slides-next",
                            asNavFor: ".popup--active .citem__left--mainslide.pop",
                            focusOnSelect: true,
                        });

                        const popup = document.querySelector('.popup--product');
                        popup.querySelector('.js-close-popup').addEventListener('click', function () {
                            onClosePopup(popup)
                        })

                        popup.addEventListener('click', function (e) {
                            e.stopPropagation();
                            if (e.target.classList.contains('popup')) {
                                onClosePopup(popup)
                            }
                        })
                    },
                });
            }

            function onClosePopup(popup) {
                let container = popup.firstElementChild
                if (popup.classList.contains('popup--active')) {
                    container.style.display = ''

                    if (popup.querySelector('.citem__left--mainslide.pop') && popup.querySelector('.citem__left--slides.pop')) {
                        popup.parentNode.querySelector('.js-show-popup[data-action="' + popup.getAttribute('data-popup') + '"]').removeAttribute('disabled')
                        $(".popup--active").find(".citem__left--mainslide.pop").slick('unslick');
                        $(".popup--active").find(".citem__left--slides.pop").slick('unslick')
                        popup.remove()
                        document.querySelector('.menu').classList.remove('header--active');
                        document.body.classList.remove('popup-open')
                        html.classList.remove('scroll')
                        header.classList.remove('header--active')
                        return
                    }

                    togglePopup(popup)
                    header.classList.remove('header--active')

                }
            }


            function toggleMenu() {
                if (body.classList.contains('menu-open') === true) {
                    body.classList.remove('menu-open')
                    body.style.overflow = ''
                    html.classList.remove('scroll')
                    html.style.overflow = ''
                } else {
                    body.classList.add('menu-open')
                    body.style.overflow = 'hidden'
                    html.style.overflow = 'hidden'
                    html.classList.add('scroll')
                }
                if (body.classList.contains('map-open') === true) {
                    body.classList.remove('map-open')
                    html.classList.remove('scroll')
                    html.style.overflow = ''
                }
            }

            function togglenMap() {
                if (body.classList.contains('map-open') === true) {
                    body.classList.remove('map-open')
                } else {
                    body.classList.add('map-open')
                }
            }

            function togglePopup(element) {
                let popItem = element ? element : false;
                if (popItem) {
                    if (body.classList.contains('menu-open') === true) {
                        body.classList.remove('menu-open')
                        body.style.overflow = ''
                        html.classList.remove('scroll')
                        html.style.overflow = 'hidden'
                    }
                    if (body.classList.contains('popup-open') === true) {
                        popItem.classList.remove('popup--active')
                        body.classList.remove('popup-open')
                        html.classList.remove('scroll')
                        body.style.overflow = ''
                        html.style.overflow = ''
                        header.classList.remove('header--active')
                    } else {
                        body.classList.add('popup-open')
                        body.style.overflow = 'hidden'
                        html.style.overflow = 'hidden'
                        popItem.classList.add('popup--active')
                        html.classList.add('scroll')
                        header.classList.add('header--active')
                    }
                }
            }

            if (wrapper.classList.contains('wrapper--index')) {
                if (promo)
                    new Opacity(true, promo, window.innerHeight / 6, window.innerHeight / 2)

                if (brandsTitle)
                    new Opacity(false, brandsTitle, window.innerHeight / 2, window.innerHeight / 2 + window.innerHeight / 5)

                if (headerLocation)
                    new Opacity(true, headerLocation, 0, window.innerHeight / 2)
            }

            if (headerBurger) {
                headerBurger.addEventListener('click', (event) => {
                    toggleMenu();
                    event.preventDefault();
                });
            }
            if (contactLink) {
                contactLink.addEventListener('click', (event) => {
                    headerBurger.click();
                    event.preventDefault();
                });
            }

            if (document.querySelector('.js-map')) _self.map();

            if (location) {
                location.addEventListener('click', (event) => {
                    toggleMenu()
                    setTimeout(() => {
                        togglenMap()
                    }, 500);
                    event.preventDefault();
                });
            }

            if (closemap) {
                closemap.addEventListener('click', (event) => {
                    togglenMap();
                    event.preventDefault();
                });
            }
            if (openmap) {
                openmap.addEventListener('click', (event) => {
                    togglenMap();
                    event.preventDefault();
                });
            }

            if (closePopup.length && popups.length) {
                for (let itemBtnClose of closePopup) {
                    const dataId = itemBtnClose.getAttribute('data-id');
                    itemBtnClose.addEventListener('click', (event) => {
                        if (document.querySelector('.unset-popup--notification[data-popup="' + dataId + '"]')) {
                            document.querySelector('.unset-popup--notification[data-popup="' + dataId + '"]').style.display = 'none'
                        }
                        for (let popup of popups) {
                            onClosePopup(popup)
                        }
                        event.preventDefault()
                    });
                }
            }

            if (grayBtn) {
                for (let i = 0; i < grayBtn.length; i++) {
                    grayBtn[i].addEventListener('click', function () {
                        if (prevPopup) {
                            prevPopup.style.display = 'none';
                        }
                        if (grayBtn[i].parentNode.querySelector('.unset-popup')) {
                            grayBtn[i].parentNode.querySelector('.unset-popup').style.display = 'block';
                            prevPopup = grayBtn[i].parentNode.querySelector('.unset-popup');
                        }
                    })
                }
            }

            if (showPopup.length && popups.length) {
                for (let itemBtn of showPopup) {
                    let btnData = itemBtn.dataset.action
                    itemBtn.addEventListener('click', (event) => {
                        if (itemBtn.classList.contains('js-show-popup-product')) {
                            openPopupProduct(itemBtn)
                        }
                        for (let popup of popups) {
                            let container = popup.firstElementChild,
                                popupData = popup.dataset.popup
                            if (btnData == popupData && popupData != 'map') {
                                container.style.display = 'block'

                                if (popup.classList.contains('popup-size-table')) {
                                    if (document.querySelector('.popup--product')) {
                                        document.querySelector('.popup--product').remove()
                                    }
                                }

                                togglePopup(popup)
                                if (prevPopup) {
                                    prevPopup.style.display = 'none';
                                }

                                if (popup.classList.contains("popup-pre-order")) {
                                    popup.querySelector(".popup__id").setAttribute('value', itemBtn.getAttribute('data-id'))
                                }

                                if (popup.classList.contains("popup-sony")) {
                                    popup.querySelector(".js-popup-sony-link").setAttribute('href', itemBtn.getAttribute('data-link'))
                                }

                                popup.addEventListener('click', function (e) {
                                    e.stopPropagation();
                                    if (e.target.classList.contains('popup'))
                                        onClosePopup(popup)
                                })

                            } else if (btnData == popupData && popupData == 'map') {
                                container.style.display = 'block'
                                popup.classList.add('popup--active')
                                if ($('#yandex_map')) {
                                    var myMap;
                                    var coords = $(itemBtn).attr('data-coords');

                                    ymaps.ready(init);

                                    function init() {
                                        var coords = $(itemBtn).data('coords'),
                                            text = $(itemBtn).data('text');

                                        myMap = new ymaps.Map('yandex_map', {

                                            center: [[56.05598527595137, 92.94089399999984]],
                                            zoom: 11
                                        }, {
                                            searchControlProvider: 'yandex#search'
                                        });

                                        myMap.setCenter(coords, 16);

                                        var myPlacemark = new ymaps.Placemark(coords, {
                                                balloonContent: text
                                            },
                                            {
                                                preset: 'islands#icon',
                                                iconColor: '#0095b6'
                                            });
                                        myMap.geoObjects.add(myPlacemark);
                                        myPlacemark.balloon.open();

                                    }

                                    $('.js-close-map').click(function () {
                                        myMap.destroy();
                                        popup.classList.remove('popup--active')
                                        container.style.display = 'none'

                                    })
                                }
                            }
                        }
                        event.preventDefault()
                    })
                }
            }

            if (document.querySelectorAll(".js-lightgallery").length) {
                $(document.querySelectorAll(".js-lightgallery")).lightGallery({
                    selector: 'a'
                });
            }

            const popupTableSize = document.querySelector('.popup-size-table') || false;
            if (popupTableSize) {
                const titlesLength = popupTableSize.querySelector('.table-content__titles').children.length;
                document.documentElement.style.setProperty('--table-size-columns', titlesLength);
            }

            let wrap = (query) => {
                document.querySelectorAll(query).forEach(elem => {
                    const div = document.createElement('div');
                    div.classList.add('table-wrap');
                    elem.parentElement.insertBefore(div, elem);
                    div.appendChild(elem);
                });
            };

            wrap('.text table');

            window.dispatchEvent(new Event('resize'));

            return this;

        }


    }.init();

    $(".js-card-toggle").click(function (e) {
        e.preventDefault();
        const $card = $('.card');
        const form = $(".js-check-card");
        $.ajax({
            url: form.attr('action') + form.attr('name') + '/',
            type: "POST",
            data: form.serialize(),
            success: function (response) {
                $card.find('.card__info').html(response.html).promise().done(function () {
                    document.querySelector('.js-card-toggle').parentElement.classList.toggle('card--open');
                    window.scrollTo({
                        top: e.target.offsetTop - 100,
                        behavior: 'smooth'
                    })
                });
            },
            error: function (response) {
                $card.find('.card__info').html(response.html).promise().done(function () {
                    document.querySelector('.js-card-toggle').parentElement.classList.toggle('card--open');
                    window.scrollTo({
                        top: e.target.offsetTop - 100,
                        behavior: 'smooth'
                    })
                });
            }
        });
    });

    $('.catalog__menu--button').click(function () {
        document.querySelector('.catalog__menu--button').classList.toggle('active');
        document.querySelector('.menu__inner').classList.toggle('active');
    });

    const catalogAsideHeading = document.querySelectorAll('.catalog__aside--heading')

    if (catalogAsideHeading.length) {
        catalogAsideHeading.forEach((item) => {
            item.addEventListener('click', (e) => {
                const wrapper = e.target.closest('.catalog__aside__wrapper')
                wrapper.querySelector('.hide-svg').classList.toggle('active');
                if (wrapper.querySelector('.catalog__aside--list')) {
                    wrapper.querySelector('.catalog__aside--list').classList.toggle('active');
                    return
                }
                if (wrapper.querySelector('.catalog__aside--shoplinks')) {
                    wrapper.querySelector('.catalog__aside--shoplinks').classList.toggle('active');
                    return
                }
                if (wrapper.querySelector('.catalog__aside--filters')) {
                    wrapper.querySelector('.catalog__aside--filters').classList.toggle('active');
                }
            })
        })
    }

    // Табы на странице товара
    $('.citem__description--tab').click(function () {
        var id = $(this).attr('data-behavior'),
            content = $('.citem__description--text[data-tab="' + id + '"]');

        $('.citem__description--tab.active').removeClass('active');
        $(this).addClass('active');

        $('.citem__description--text.active').removeClass('active');
        content.addClass('active');
    });

    // Слайдер товара на странице товара
    $(".citem__left--mainslide.main").slick({
        arrows: false,
        fade: true,
        draggable: false,
        asNavFor: ".citem__left--slides.main",
    });

    // Малый слайдер товара на странице товара
    $(".citem__left--slides.main").slick({
        dots: false,
        arrows: true,
        infinite: true,
        slidesToShow: 3,
        prevArrow: ".citem__left--slides-prev",
        nextArrow: ".citem__left--slides-next",
        asNavFor: ".citem__left--mainslide.main",
        focusOnSelect: true,
    });

    // Отображение товаров в заказе в личном кабинете
    $('.js-cabinet-row').click(function () {
        $(this).toggleClass('active')
        $(this).children('.history__row--additional').toggleClass('active')
    });

    // Отображение чекбоксов второго уровня в корзине
    $('.firstlevel').change(function () {
        let parent = $(this).parents('.js-radio-wrap'),
            secondLevel = parent.children('.js-radio-inner')

        $('.js-radio-inner').removeClass('active')
        if ($(this).prop('checked') == true) {
            secondLevel.addClass('active')
        }
    });

    // Отображение пароля в инпуте
    $('.js-toggle-pass').click(function (e) {
        e.preventDefault();
        $(this).toggleClass('active')
        let input = $(this).siblings('.inputs__input--wrap').children(':input')
        if (input.prop('type') == 'password') {
            input.prop('type', 'text')
        } else {
            input.prop('type', 'password')
        }
    });

    const dropContents = document.querySelectorAll('.js-drop-content');
    if (dropContents.length) {
        document.querySelector('.catalog__aside--filters').classList.add('active');
        for (let i = 0; i < dropContents.length; i++) {
            if (dropContents[i].clientHeight > 100) {
                dropContents[i].style.height = '95px';
                for (let j = 3; j < dropContents[i].children.length; j++) {
                    dropContents[i].children[j].style.display = 'none'
                }
            } else {
                dropContents[i].parentElement.querySelector('.button--drop').remove();
            }
        }
    }
    if (window.innerWidth < 1200 && document.querySelector('.catalog__aside--filters')) {
        document.querySelector('.catalog__aside--filters').classList.remove('active');
    }
    // Раскрывающийся список для каталога
    $(".js-drop-btn").on("click", function (e) {
        e.preventDefault();
        var name = $(this).attr("data-name");
        if (name) {
            var content = $(".js-drop-content[data-name=" + name + "]")

            if (content.css('height') === '95px') {
                this.innerText = "Скрыть";
                content.css('height', content.prop('scrollHeight') + 'px')
                for (let j = 3; j < content[0].children.length; j++) {
                    content[0].children[j].style.display = 'block'
                }
                content.css('height', '210px')
                content.css('overflow-y', 'scroll');
            } else {
                this.innerText = "Показать все";
                content.css('height', content.prop('scrollHeight') + 'px')
                window.getComputedStyle(document.querySelector(".js-drop-content[data-name=" + name + "]"), null).getPropertyValue('height');
                content.css('height', '95px');
                content.css('overflow-y', 'hidden');
            }
            content.on('transitionend', function () {
                if (content.css('height') === '95px') {
                    for (let j = 3; j < content[0].children.length; j++) {
                        content[0].children[j].style.display = 'none'
                    }
                }
            })
        }
    });


    const selectFirstVisibleRadioInPaymentMethod = () => {
        const radioButton = document.querySelector('.cart__payment .radio:not(.hidden) .cart__payment--input');
        radioButton.click();
    }

    const togglePaymentMethod = (deliveryMethodActive) => {
        const paymentMethodElements = document.querySelectorAll('.cart__payment .radio');
        if (!paymentMethodElements.length) {
            return
        }

        const paymentAndDeliveryMethods = {
            pickup: ['card', 'card_getting', 'cash'],
            delivery_in: ['card'],
            delivery_out: ['card']
        }

        const paymentMethods = paymentAndDeliveryMethods[deliveryMethodActive];

        if (paymentMethods) {
            paymentMethodElements.forEach((element) => {
                element.classList.add('hidden')
                const inputValue = element.querySelector('.cart__payment--input').getAttribute('value');
                if (paymentMethods.includes(inputValue)) {
                    element.classList.remove('hidden')
                }
            })
        }
        selectFirstVisibleRadioInPaymentMethod()
    }

    // Скрытие/показ полей адрес доставки и стоимости доставки в корзине
    $(".js-radio-show-input").find('input').on('change', function (e) {
        if ($(this).attr('value') == "pickup") {
            $('.box__input--address').css('display', "none")
            $('.info__dissingle--delivery').css('display', "none")
        } else {
            $('.box__input--address').css('display', "grid")
            $('.info__dissingle--delivery').css('display', "flex")
        }

        togglePaymentMethod($(this).attr('value'));
    })
})
