function debounce(func, delay) {
    let searchTimeout = null

    return function (args) {
        if (searchTimeout) {
            clearTimeout(searchTimeout)
        }

        searchTimeout = setTimeout(function () {
            func(args)
        }, delay)
    }
}