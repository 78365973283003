const homePageSliderElement = document.querySelector('.js-slider-home-page');
if (homePageSliderElement) {
    const homePageTitleElement = document.querySelector('.js-home-page-title');
    const headerElement = document.querySelector('.header');
    const wrapperHomePageElement = document.querySelector('.js-wrapper-home-page');
    document.addEventListener('DOMContentLoaded', calcMarginHomePage)
    window.addEventListener('resize', calcMarginHomePage)
    window.addEventListener('scroll', calcMarginHomePage)
    function calcMarginHomePage() {
        const titleHeight = homePageTitleElement.clientHeight
        const sliderHeight = homePageSliderElement.clientHeight
        const headerHeight = headerElement.clientHeight
        const margin = 24
        const pagePadding = (sliderHeight + headerHeight + margin)
        homePageTitleElement.style.top = pagePadding + 'px'
        wrapperHomePageElement.style.paddingTop = (pagePadding + titleHeight) + 'px'
    }
}
