//Интерфейс для работы с корзиной
var symbolRuble = " ₽";
//обновления блока с информацией о корзине
const beautyPriceDanil = function (price) {
    if (typeof price !== "number") {
        price = parseFloat(price, 10);
    }
    price = Math.floor(price)
    // 2123399.2034 => 2 123 399,2
    if (price === 0) {
        return 0;
    }
    price = price.toFixed(2);
    price = price.split('.');
    price[0] = price[0].replace(/(\d)(?=(\d{3})+$)/g, "$1 ");

    if (price[1] > 0) {
        return price[0] + "." + price[1];
    }
    return price[0];
};
var updateCartBlock = function () {
    var totalSum = 0,
        totalCount = 0,
        $cart = $(".cart");

    for (var i = 0; i < Cart.items.length; i++) {
        totalCount += parseInt(Cart.items[i].qty, 10);
    }

    var totalQtyText = Meta.decline(totalCount, "", "%n товар", "%n товара", "%n товаров");

    $cart.find(".added-to_cart").text(totalCount > 99 ? 99 : totalCount);
    $cart.find(".in-cart_added").text(totalQtyText);

    const cartItem = document.querySelectorAll('.js-product-cart');
    if (cartItem.length) {
        // Скидка по карте (Правый столбец)
        const cardDiscount = document.querySelector('.js-discount-sum');
        // Стоимость заказа (Правый столбец)
        const orderCost = document.querySelector('.js-order-sum');
        // Итоговая цена (Элемент)
        const orderCostInformation = document.querySelector('.js-cart-information-price');
        // Итоговое количество под товарами (Элемент)
        const qtyInformation = document.querySelector('.js-cart-information-qty');
        // Итоговая стоимость с учетом скидки под товарами (Элемент)
        const cardDiscountInformation = document.querySelector('.js-cart-information-discount-price');
        // Итоговая стоимость с учетом скидки по карте (Правый столбец)
        const totalSum2 = document.querySelector('.total__summ');
        // Итоговая скидка по категории: Техника/электроника
        const discountTechnic = document.querySelector('.js-cart-technic');
        // Итоговая скидка по категории: Обувь/одежда
        const discountClothes = document.querySelector('.js-cart-clothes');


        // Итоговая стоимость (переменная)
        let finalPrice = 0;
        // Итоговое количество (переменная)
        let finaQty = 0;
        // Итоговая стоимость с учетом скидки (переменная)
        let finalDiscountPrice = 0;
        // Скидка по карте с учетом категорий
        let finalDiscountPriceWithCategories = 0;
        // Итоговая сумма счетчик
        let finalPriceCount = 0;
        // Счетчик для категори: Техника/электроника
        let technicCategory = 0;
        // Счетчик для категории: Обувь/одежда
        let clothesCategory = 0;

        for (let i = 0; i < cartItem.length; i++) {
            // Находим элемент цены с учетом скидки
            const discountPriceItem = cartItem[i].querySelector('.js-cart-item-discount-sum');
            // Находим значение инпута в товаре
            const qty = cartItem[i].querySelector('.amount').value;
            // Получаем данные из аттрибутов конкретного товара
            const dataDiscountPrice = cartItem[i].getAttribute('data-discount-price');
            const dataPrice = cartItem[i].getAttribute('data-price');
            const dataClothes = cartItem[i].getAttribute('data-clothes');
            const dataTechnic = cartItem[i].getAttribute('data-technic');
            // Считаем цену с учетом скидки
            discountPriceItem.textContent = beautyPriceDanil(Number(dataDiscountPrice) * qty) + symbolRuble;

            // БЛОКА СКИДКИ ПО КАТЕГОРИЯМ
            const technicCategoryItem = Number(dataTechnic) * qty;
            const clothesCategoryItem = Number(dataClothes) * qty;

            technicCategory += Number(technicCategoryItem);
            clothesCategory += Number(clothesCategoryItem);

            // Считаем общую сумму, количество и цену с учетом скидки
            finalPrice += Number(dataPrice * qty)
            finaQty += Number(qty);
            finalDiscountPrice += Number(dataDiscountPrice * qty)
            finalDiscountPriceWithCategories = technicCategory + clothesCategory;
            finalPriceCount = finalPrice - finalDiscountPriceWithCategories;
            finalDiscountPriceWithCategories = Math.round(finalPrice) - Math.round(finalDiscountPrice);
            // Вывод скидки по карте для правого столбца
            if (cardDiscount) {
                if (finalDiscountPriceWithCategories !== 0) {
                    cardDiscount.textContent = '− ' + beautyPriceDanil(finalDiscountPriceWithCategories) + symbolRuble
                } else {
                    cardDiscount.textContent = '0' + symbolRuble
                }
            }
            // Вывод стоимости заказа для правго столбца
            orderCost.textContent = beautyPriceDanil(finalPrice) + symbolRuble;
            // Вывод итоговой цены для правого столбца
            totalSum2.textContent = (beautyPriceDanil(finalPriceCount) + symbolRuble);
        }
        if (discountTechnic && discountClothes) {
            discountTechnic.textContent = beautyPriceDanil((technicCategory)) + symbolRuble
            discountClothes.textContent = beautyPriceDanil((clothesCategory)) + symbolRuble
        }

        // Вывод итоговой цены с учетом скидки
        cardDiscountInformation.textContent = beautyPriceDanil(finalDiscountPrice) + symbolRuble;
        // Вывод итоговой цены
        orderCostInformation.textContent = beautyPriceDanil(finalPrice) + symbolRuble
        // Вывод итогового количества
        qtyInformation.textContent = finaQty + " шт";

        const delivery = document.querySelector(".order__delivery") || false;
        const radios = document.querySelectorAll(".firstlevel") || false;
        for (let i = 0; i < radios.length; i++) {
            radios[i].addEventListener('change', function () {
                const radioAttribute = radios[i].getAttribute('data-delivery');
                if (radios[i].getAttribute('value') === 'pickup') {
                    totalSum2.textContent = (beautyPriceDanil(finalPriceCount) + symbolRuble);
                } else if (radios[i].getAttribute('value') === 'delivery_in') {
                    totalSum2.textContent = (beautyPriceDanil(finalPriceCount + Number(radioAttribute)) + symbolRuble);
                    delivery.textContent = '+ ' + radioAttribute + symbolRuble;
                } else if (radios[i].getAttribute('value') === 'delivery_out') {
                    totalSum2.textContent = (beautyPriceDanil(finalPriceCount) + symbolRuble);
                    delivery.textContent = radioAttribute;
                }
            })
        }
    }
};

//обновлялка инфы на странице корзины
// var updateCartPage = function () {
//     var totalPrice = 0;
//     for (var item_id in Cart.items) {
//         // var el = document.getElementById(Cart.items[item_id].id);
//         totalPrice += Cart.items[item_id].price * Cart.items[item_id].qty;
//     }
//
//     $(".total__summ").text(Meta.beautyPrice(totalPrice) + ' ₽');
// };

function cartBuy(e, el) {
    var $product = el.closest(".js-product");
    var id = parseInt($product.attr('data-id'), 10),
        qty = parseInt($product.find(".js-count input").val()),
        price = parseInt($product.find(".cost-catalog").attr("data-price")),
        //  choice = $product.attr('data-choice'),
        choices = $product.find(".js-select_item"),
        choice = 'не выбрано',
        $in_cart = $product.find('.js-cart_count_in'),
        entry_count = $product.find('.js-entry_count'),
        cart_count = parseInt($in_cart.text());

    if (!$in_cart.hasClass("in_a")) {
        $in_cart.addClass("in_a");
    }
    if (!entry_count.hasClass('show_count')) {
        entry_count.addClass('show_count');
    }


    qty = qty ? qty : 1;


    for (var i = 0; i < choices.length; i++) {
        if (choices[i].checked) {
            choice = choices[i].value;
        }
    }

    if (cart_count > 0) {
        $in_cart.text(cart_count + qty);
    } else {
        $in_cart.text(qty);
    }

    var item = Cart.getItem(id);
    if (item) {
        if (item.choice === choice) {
            Cart.putItem({
                    id: id,
                    qty: item.qty + qty,
                    price: price,
                    choice: choice,
                }, true, function () {
                    updateCartBlock();
                    checkProductOverLimit(0, 1, product.over_limit)
                }
            );
        } else {
            Cart.putItem({
                    id: id,
                    qty: qty,
                    price: price,
                    choice: choice,
                }, false, function () {
                    updateCartBlock();
                    checkProductOverLimit(0, 1, product.over_limit)
                }
            );
        }
    } else {
        Cart.putItem({
                id: id,
                qty: qty,
                price: price,
                choice: choice,
            }, false, function (product) {
                updateCartBlock();
                checkProductOverLimit(0, 1, product.over_limit)
            }
        );
    }
    /*$product.find('.cart-block').addClass('added');
    $product.find('.buy_button').hide();*/
    $product.find(".js-count input").val(1);
    $product.addClass("added_entry");
    setTimeout(function () {
        $product.removeClass('added_entry')
    }, 1000);
    e.preventDefault();
}

$(function () {
    //кнопка купить
    $('.js-product').on("click", ".js-cart_buy", function (e) {
        cartBuy(e, $(this))
    });
    // ************ Проверка на клик для закрытия попапа на странице корзины ***************
    const body = document.querySelector('body');
    body.addEventListener('click', function (e) {
        if (!e.target.classList.contains('js-open-unset-popup-show-discount') && !e.target.closest('.unset-popup--show-discount')) {
            let popups = body.querySelectorAll('.unset-popup--show-discount')
            for (let popup of popups) {
                popup.style.display = 'none';
            }
        }
    })
    $('.js-ajax-form').on("keydown", function (e) {
        if (e.keyCode === 13) {
            e.preventDefault();
        }
    })

    // ************ страница корзины ***************
    $('.js-cart-item').map(function () {
        var $product = $(this),
            id = parseInt($product.attr('data-id'), 10),
            choice = $product.attr('data-choice'),
            price = parseInt($product.attr('data-price'), 10),
            show_remove = $product.find('.js-cart-remove__show'),
            pop__up = show_remove.find(".unset_popup");
        show_remove.on('click', function () {
            pop__up.toggleClass("active");
        });

        $product.find('.js-open-unset-popup-delete-element').on('click', function (e) {
            e.preventDefault();
            $product.find('.unset-popup--delete-element').show();
        });
        $product.find('.js-open-unset-popup-show-discount').on('click', function (e) {
            e.preventDefault();
            $product.find('.unset-popup--show-discount').toggle();
        });
        $product.find('.js-cart-remove').on('click', function (e) {
            if (Cart.items.length > 1) {
                $product.slideUp(500, function () {
                    $(this).remove();
                });
            } else {
                $product.slideUp(500, function () {
                    $(this).remove();
                });
                $('.cart-wrap').html('<div class="cart_back_link"><h3>Ваша корзина пуста</h3><a href="/catalog/">Продолжить покупки</a> </div>');
            }

            e.target.closest('.js-product-cart').remove();

            Cart.removeItem({id: id, choice:choice}, function () {
                updateCartBlock();
                if (!Cart.items.length) {
                    location.reload()
                }
            });

            e.preventDefault();
        });

        $product.find('.js-cart-plus').on('click', function (e) {
            var inputVal = ($product.find(".js-count input").val().replace("/\,/", '.') * 1);

            var newValue = checkProductQty($product, inputVal + 1)

            Cart.putItem({
                id: id,
                choice: choice,
                qty: newValue,
                price: price,
            }, true, function (product) {
                newValue = product.qty
                $product.find(".js-count input").val(newValue);
                $product.find(".js-cart-item-sum").text(Meta.beautyPrice(price * newValue) + ' ₽');

                updateCartBlock();
                checkProductOverLimit(inputVal, newValue, product.over_limit)
            });
            e.preventDefault();
        });

        $product.find('.js-cart-minus').on('click', function (e) {
            var inputVal = ($product.find(".js-count input").val().replace(/\,/, '.') * 1);

            var newValue = checkProductQty($product, inputVal - 1)

            Cart.putItem({
                id: id,
                choice: choice,
                qty: newValue,
                price: price,
            }, true, function (product) {
                newValue = product.qty
                $product.find(".js-count input").val(newValue);
                $product.find(".js-cart-item-sum").text(Meta.beautyPrice(price * newValue) + ' ₽');

                updateCartBlock();
            });

            e.preventDefault();
        });

        $product.find('.js-count input').on('beforeinput', function (e) {
            this.old_value = +$(this).val()
        });

        $product.find('.js-count input').on('input', function (e) {
            var self = $(this)
            var oldValue = this.old_value
            var newValue = parseInt($(this).val(), 10);

            newValue = checkProductQty($product, newValue)

            const maxItemQty = +($($product).attr('data-max-qty'))

            if (newValue >= maxItemQty) {
                self.val(maxItemQty);
            }

            Cart.putItem({
                id: id,
                choice: choice,
                qty: newValue,
                price: price,
            }, true, function (product) {
                newValue = product.qty
                self.val(newValue);

                $product.find(".js-cart-item-sum").text(Meta.beautyPrice(price * newValue) + ' ₽');
                updateCartBlock();
                checkProductOverLimit(oldValue, newValue, product.over_limit)
            });

            e.preventDefault();
        });

    });

    Cart.onInitCallback = function () {
        updateCartBlock();
    };
});

function checkProductQty($product, newValue) {
    if (!newValue || newValue <= 0) {
        newValue = 1;
    } else {
        newValue = parseInt(newValue);
    }
    return newValue
}

function checkProductOverLimit(oldValue, newValue, overLimit) {
    const cart = new UserCart()
    if (overLimit) {
        cart.showPopupAddProduct('Количество выбранного товара превышает количество на складе')
    } else if (oldValue < newValue) {
        cart.showPopupAddProduct('Товар успешно добавлен в корзину')
    }
}

$('.js-unset-popup-close').on('click', function (e) {
    $(this).closest('.unset-popup').hide();
});

$('.js-clear-display').on('click', function () {
    $(".unset-popup--delete-all-elements").show();
});

$('.js-cart-clear').on('click', function () {
    Cart.removeAll();
    $('.cart-wrap').html('<div class="cart_back_link"><h3>Ваша корзина пуста</h3><a href="/catalog/">Продолжить покупки</a> </div>');
    document.location.href = $(this).attr('data-redirect');
});

$('.js-select_item').change(function () {
    var $price = $('.js-select_item option:selected').attr('data-price');
    if ($price) {
        $('.js-product').find('.price').text(Meta.beautyPrice($price * 1) + ' ₽');
        $('.js-product').find('.cost-catalog').attr('data-price', $price);
    }
});

