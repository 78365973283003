const searchSelectors = {
    searchBlock: '.header__search__wrapper',
    iconSearch: '.button-search__search',
    iconClose: '.button-search__close',
    buttonSubmit: '.js-submit-search',
    wrapper: '.search__wrapper',
    formSearch: '.js-search-form',
    results: '.search__results'
}

const searchPathUrl = '/search/'

const buttonSearch = document.querySelector('.js-button-search');
const headerNav = document.querySelector('.nav');
const buttonSearchIconClose = buttonSearch.querySelector(searchSelectors.iconClose)
const buttonSearchIcon = buttonSearch.querySelector(searchSelectors.iconSearch);
const headerSearchBlock = document.querySelector(searchSelectors.searchBlock);
const inputSearch = document.querySelector('.js-search-input')
const formSearch = document.querySelectorAll(searchSelectors.formSearch)
const searchResultsWrapper = document.querySelector('.search__results-wrapper');

const mobileScreen = 991

let searchActive = false;
let ResultListActive = false;

const setDefaults = () => {
    searchActive = false;
    headerNav.removeEventListener("transitionend", onHeaderNavTransitionend)
    headerNav.classList.remove('hidden')
    headerNav.classList.remove('nav--hidden')
    headerSearchBlock.removeEventListener('transitionend', closeSearch)
    headerSearchBlock.style.transition = 'none'
    headerSearchBlock.classList.add('hidden-search')
    headerSearchBlock.style.marginLeft = 0
    setTimeout(() => {
        headerSearchBlock.style.transition = ''
    })
    buttonSearchIconClose.classList.remove('active')
    buttonSearchIcon.classList.add('active')
}

const toggleSearch = () => {
    if (searchActive) {
        headerNav.removeEventListener("transitionend", onHeaderNavTransitionend)
        searchActive = false
        closeSearchField()
        headerSearchBlock.addEventListener('transitionend', closeSearch)
    } else {
        headerSearchBlock.removeEventListener('transitionend', closeSearch)
        searchActive = true;
        headerNav.addEventListener("transitionend", onHeaderNavTransitionend)
        openSearch()
    }
}

const closeSearch = () => {
    headerSearchBlock.removeEventListener('transitionend', closeSearch)
    headerSearchBlock.style.marginLeft = 0
    buttonSearchIconClose.classList.remove('active')
    buttonSearchIcon.classList.add('active')
    headerNav.classList.remove('hidden')
    setTimeout(() => {
        headerNav.classList.remove('nav--hidden')
    })
}

const openSearch = () => {
    if (headerNav.classList.contains('nav--hidden')) {
        setDefaults()
        return
    }
    buttonSearchIconClose.classList.add('active')
    buttonSearchIcon.classList.remove('active')
    headerNav.classList.add('nav--hidden')
    inputSearch.focus()
}

function onHeaderNavTransitionend(e) {
    e.target.classList.add('hidden')
    openSearchField()
    headerNav.removeEventListener("transitionend", onHeaderNavTransitionend)
}

const openSearchField = () => {
    headerSearchBlock.style.marginLeft = 'auto'
    headerSearchBlock.classList.remove('hidden-search')
}

const closeSearchField = () => {
    if (headerSearchBlock.classList.contains('hidden-search')) {
        setDefaults()
        return
    }
    headerSearchBlock.classList.add('hidden-search')
}

window.addEventListener('click', function (e) {
    const target = e.target;

    if (target.closest(searchSelectors.buttonSubmit)) {
        changeRoute(target)
    }

    if (window.location.pathname === searchPathUrl) return;

    if ((searchActive && !target.closest(searchSelectors.searchBlock)) || target.closest(searchSelectors.iconClose)) {
        closeResultsList()
        toggleSearch()
        return
    }
    if (target.closest(searchSelectors.iconSearch)) {
        toggleSearch()
    }
})

const closeResultsList = () => {
    searchResultsWrapper.innerHTML = ''
    ResultListActive = false
}

let oldScroll = 0;

const showSearchMobile = () => {
    headerSearchBlock.classList.remove('hidden-search-mobile')
    headerSearchBlock.classList.remove('hidden-search')
}

const hideSearchMobile = () => {
    headerSearchBlock.classList.add('hidden-search-mobile')
    headerSearchBlock.classList.add('hidden-search')
}

function moveSliderHomePage() {
    let currentScrollPosition = window.scrollY;
    const headerHeight = 74;
    const sliderHomePage = document.querySelector('.slider-home-page')

    if (sliderHomePage){
        if (window.innerWidth <= mobileScreen && currentScrollPosition < headerHeight) {
            sliderHomePage.style.marginTop = '50px';
        }else {
            sliderHomePage.style.marginTop = '0';
        }
    }
}

function toggleSearchMobile() {
    let currentScrollPosition = window.scrollY;

    const scrollDirection = oldScroll < currentScrollPosition ? "down" : "up"

    const headerHeight = 74;

    const sliderHomePage = document.querySelector('.slider-home-page')

    if (scrollDirection === "down" && currentScrollPosition >= headerHeight) {
        hideSearchMobile()
        if (sliderHomePage) sliderHomePage.style.marginTop = '0';
    } else {
        showSearchMobile()
        if (sliderHomePage) sliderHomePage.style.marginTop = '50px';
    }
    headerSearchBlock.style.transition = 'none'

    oldScroll = currentScrollPosition
}

const changeRoute = (target) => {
    if (target) {
        const input = target.closest(searchSelectors.wrapper).querySelector('input')
        if (input.value.trim().length >= 3) {
            window.location.href = window.location.origin + '/search/?q=' + input.value.trim();
        } else {
            const warningMessageEl = target.closest('.page-search__header');
            warningMessageEl ? warningMessageEl.querySelector('.page-search__not-found').textContent = 'Запрос должен быть не менее 3-х символов' : ''
        }
    }
}

formSearch.forEach((item) => {
    item.addEventListener('submit', function (e) {
        e.preventDefault()
        changeRoute(e.target);
    })
})


const getSearchResult = (e) => {
    closeResultsList()
    if (e.target.value.length >= 3) {
        $.ajax('/search/sagest/?q=' + e.target.value, {
            cache: false
        }).done(function (response) {
            if (response.success === true) {
                searchResultsWrapper.innerHTML = response.html
                ResultListActive = true
            } else {
                searchResultsWrapper.innerHTML = `<ul class=search__results>
    <li class=results__link>По запросу «${e.target.value}» ничего не найдено</li>`
            }
        })
    } else {
        e.target.value.length !== 0 ? searchResultsWrapper.innerHTML = `<ul class=search__results>
    <li class=results__link>Запрос должен быть не менее 3-х символов</li>` : closeResultsList();
    }
}

const changeColorIcon = () => {
    if (buttonSearchIcon) {
        if (window.innerWidth > mobileScreen && window.location.pathname === searchPathUrl) {
            buttonSearch.classList.add('header__button-search--red')
        } else {
            buttonSearch.classList.remove('header__button-search--red')
        }
    }
}

if (inputSearch) {
    inputSearch.addEventListener('input', debounce(getSearchResult, 300))
    inputSearch.addEventListener('focus', getSearchResult)
}

window.addEventListener("scroll", function (e) {
    if (window.innerWidth <= mobileScreen) {
        toggleSearchMobile()
    }
})

window.addEventListener("resize", () => {
    changeColorIcon()
    if (window.innerWidth > mobileScreen) {
        setDefaults()
        headerSearchBlock.classList.remove('hidden-search-mobile')
    }
    moveSliderHomePage()
})

window.addEventListener('DOMContentLoaded', function () {
    changeColorIcon()
    moveSliderHomePage()
})